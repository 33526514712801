<template>
	<div class="search">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试乘试驾查询结果"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
	</van-nav-bar>
	<SearchInput
				:search="searchInputValue"
				v-on:edit="handSearchInput"
			></SearchInput>
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<div v-show="menuTabChecked == 'list'" class="scsjs">
					<div class="scsj" v-for="item,index in scsjList" :key="index" @click="handTrial(item)">
						<div class="scsj__left">
							<div class="scsj__user">
								{{item.name}} {{item.mobile}}
							</div>
							<div class="scsj__time">
								{{item.finishtime}}
							</div>
							<div class="scsj__staffs">
								<div class="scsj__staffs__staff" v-for="itemUser in item.user" :key="itemUser.id">
									<van-image class="img_avatar" round :src="itemUser.avatar" fit="cover"/>
									<span>{{itemUser.username}}</span>
								</div>
							</div>
						</div>
						<div class="scsj__right">
							<img src="@/assets/common/icon_right.png">
						</div>
						<div class="scsj__suprcruise" v-if="item.if_cruise == 1">
							<img src="@/assets/common/icon_suprcruise.png">
						</div>
					</div>
				</div>

			
			<!-- 邀请列表 -->
				<div v-show="menuTabChecked == 'inviter'" class="inviters">
					<div class="inviter" v-for="item,index in inviterList" :key="index" @click="handInviter(item)">
						<div class="inviter__left">
							<div class="inviter__user">
								<span>{{item.customer_name}}</span><span>{{item.customer_mobile}}</span>
							</div>
							<div class="inviter__car">
								凯迪拉克 {{item.model_name}}
							</div>
							<div class="inviter__time">
								{{item.appointment_time}}
								<div v-if="item.if_check == 1" class="inviter__time__botton">
									已确认
								</div>
							</div>
						</div>
						<div class="inviter__right">
							<img src="@/assets/common/icon_right.png">
						</div>
					</div>
				</div>
			</van-list>
		</div>
</template>

<script>
import SearchInput from '@/components/searchinput.vue'
import { getDateTime2, getDateTime3 } from '@/utils/utils.js'

export default {
	components: {
		SearchInput
	},
	data() {
		return {
			menuTabChecked: 'list',
			searchInputValue: '',
			scsjList: [],
			scsjStatus: 'more',
			scsjPage: 1,
			scsjLimit: 10,
			scsjTotal: 0,
			inviterList: [],
			inviterStatus: 'more',
			inviterPage: 1,
			inviterLimit: 10,
			inviterTotal: 0,
						// 分页
			loading: false,
			finished: false,
			categories:'',
		};
	},
	mounted() {
		if (this.$route.query.type) this.menuTabChecked = this.$route.query.type
		if (this.$route.query.words) this.searchInputValue = this.$route.query.words
		if (this.$route.query.categories) this.categories = this.$route.query.categories
		if (this.menuTabChecked == 'list') this.getScsjList()
		if (this.menuTabChecked == 'inviter') this.getInviterList()
	},
	methods: {
				// 搜索内容修改
		handSearchInput(e) {
			this.searchInputValue = e.trim()
			console.log('000')
			this.scsjPage = 1
			this.inviterPage = 1
			this.scsjTotal = 0
			this.inviterTotal = 0
			this.scsjList = []
			this.inviterList = []
			this.finished = false
			if (this.menuTabChecked == 'list') this.getScsjList()
			if (this.menuTabChecked == 'inviter') this.getInviterList()
		},

		// 分页
		async onLoad(){
			if(this.menuTabChecked == 'list'){
				if(this.scsjPage == 1) return  this.loading = false;
				const {data: result} = await this.$http.post(this.categories == 'all'?'/api/scsj/search_store_scsj_log':'/api/scsj/search_user_scsj_log', {
				page: this.scsjPage,
				num: this.scsjLimit,
				words: this.searchInputValue,
				store_id:0
			})
				if (result.code == 1) {
					this.scsjPage ++
					this.scsjTotal = result.data.total
					result.data.res.forEach(v => {
						v.finishtime = getDateTime2(v.finishtime)
						v.user.forEach(vu => {
							vu.avatar = this.serverUrl(vu.avatar)
						})
						this.scsjList.push(v)
					})
				}
				console.log(this.scsjList)
			 // 加载状态结束
			 this.loading = false;
			 // 数据全部加载完成
			 if (this.scsjList.length >= this.scsjTotal) {
					this.finished = true;
				}
			}else{
				if(this.inviterPage == 1) return  this.loading = false;
					const {data: result} = await this.$http.post(this.categories == 'all'?'/api/scsj/search_store_invitation_list':'/api/scsj/search_user_invitation_list', {
					page: this.inviterPage,
					number: this.inviterLimit,
					type_id: 1,
					store_id:0,
					words: this.searchInputValue
				})
				if (result.code == 1) {
					this.inviterPage ++
					this.inviterTotal = result.data.total
					result.data.res.forEach(v => {
						v.appointment_time = getDateTime3(v.appointment_time)
						this.inviterList.push(v)
					})
				}
				// 加载状态结束
				this.loading = false;
			 // 数据全部加载完成
			 if (this.inviterList.length >= this.inviterTotal) {
					this.finished = true;
				}
			}
		},

		// 试驾详情
		handTrial(e) {
			this.$router.push({
				path: `/trial?number=${e.number}`
			})
		},
		
		// 试驾记录
		async getScsjList(e) {
			this.scsjPage = 1
			const {data: result} = await this.$http.post(this.categories == 'all'?'/api/scsj/search_store_scsj_log':'/api/scsj/search_user_scsj_log', {
				page: this.scsjPage,
				num: this.scsjLimit,
				store_id:0,
				words: this.searchInputValue
			})
			if (result.code == 1) {
				this.scsjPage ++
				this.scsjTotal = result.data.total
				result.data.res.forEach(v => {
					v.finishtime = getDateTime2(v.finishtime)
					v.user.forEach(vu => {
						vu.avatar = this.serverUrl(vu.avatar)
					})
					this.scsjList.push(v)
				})
				this.scsjStatus = 'more'
			}
		},
		
		// 邀请记录
		async getInviterList(e) {
			this.inviterPage = 1
			const {data: result} = await this.$http.post(this.categories == 'all'?'/api/scsj/search_store_invitation_list':'/api/scsj/search_user_invitation_list', {
				page: this.inviterPage,
				store_id:0,
				number: this.inviterLimit,
				type_id: 1,
				words: this.searchInputValue
			})
			if (result.code == 1) {
				this.inviterPage ++ 
				this.inviterTotal = result.data.total
				result.data.res.forEach(v => {
					v.appointment_time = getDateTime3(v.appointment_time)
					this.inviterList.push(v)
				})
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.scsjs{
	width: 100%;
	padding: 0 32px 30px;
	box-sizing: border-box;
	.scsj{
		position: relative;
		margin-bottom: 30px;
		padding: 20px 32px;
		width: 100%;
		box-sizing: border-box;
		background: #373C46;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__user{
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 36px;
		}
		&__time{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
		}
		&__staffs{
			margin-top: 15px;
			display: flex;
			&__staff{
				font-size: 26px;
				font-family: HYQiHeiY3-45;
				color: #ECECEC;
				height: 40px;
				display: flex;
				align-items: center;
				margin-right: 20px;
				.img_avatar{
					width: 40px;
					height: 40px;
					border-radius: 50%;
					margin-right: 10px;
				}
			}
		}
		&__right{
			img{
				width: 40px;
				height: 32px;
			}
		}
		&__suprcruise{
			position: absolute;
			right: 0;
			top: 0;
			img{
				width: 118px;
				height: 118px;
			}
		}
	}
	.scsj:last-child{
		margin-bottom: 0;
	}
}
.inviters{
	width: 100%;
	padding: 0 32px 30px;
	.inviter{
		margin-bottom: 30px;
		padding: 20px 32px;
		width: 100%;
		background: #373C46;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__user{
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 36px;
			text:first-child{
				color: #fff;
				margin-right: 10px;
			}
		}
		&__car{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
		}
		&__time{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
		}
		&__right{
			img{
				width: 40px;
				height: 32px;
			}
		}
	}
	.inviter:last-child{
		margin-bottom: 0;
	}
}
</style>
